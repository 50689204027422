import { LitElement } from "lit"
import { errorReload } from "#js/components/http"
import { gettext } from "#js/components/i18n"

/**
 * Load and store state in url params.
 *
 * Keep url params and component state in sync, to utilize the browsers history
 * functionality.On browser back the state of the component is restored,
 * and on navigation change the state of the component is lost.
 *
 * The component emits 'filter-changed' event on state changes.
 *
 * Components inheriting have to define defaultState, but not the state itself.
 *
 * The defaultState requires a specific structure:
 *
 *   defaultState: {
 *     [URL PARAM KEY]: {
 *       value: [ACTUAL VALUE],
 *       type: [OPTIONAL TYPE FOR EXAMPLE `Array`],
 *     },
 *     [OTHER PARAM KEY]: ...
 *   }
 *
 *  The value from component that needs to be stored is accessible with:
 *
 *    state.[URL PARAM KEY].value
 */
export class UrlBasedComponent extends LitElement {
  // state to assume on reset and on first load
  defaultState = {}
  state = {}

  connectedCallback() {
    try {
      this.state = structuredClone(this.defaultState)
      super.connectedCallback()
      this.loadStateFromUrl()
    } catch (e) {
      if (e instanceof ReferenceError) {
        errorReload(
          gettext(
            "Your browser version is too old. You should update your browser or get technical help.",
          ),
        )
      }
    }
  }

  /**
   * Load state from url params, if they exist.
   */
  loadStateFromUrl() {
    const url = new URL(globalThis.location)
    Object.keys(
      this.state,
    ).filter(
      (key) => url.searchParams.has(key),
    ).forEach(
      (key) => {
        if (this.state[key].type === "Array") {
          this.state[key].value = url.searchParams.getAll(key)
        } else if (this.state[key].type === "Boolean" && url.searchParams.has(key)) {
          this.state[key].value = url.searchParams.get(key) === "true"
        } else if (this.state[key].type === "Number") {
          this.state[key].value = parseInt(url.searchParams.get(key))
        } else if (this.state[key].type === "Date") {
          this.state[key].value = new Date(url.searchParams.get(key))
        } else {
          this.state[key].value = url.searchParams.get(key)
        }
      },
    )
  }

  /**
   * Update url params with the current state of the component.
   * Array representations are handled as multiple params.
   */
  updateUrlParams() {
    const url = new URL(globalThis.location)
    Object.entries(this.state)
      .forEach(([key, state]) => {
        url.searchParams.delete(key)
        if (
          JSON.stringify(state.value) !== JSON.stringify(this.defaultState[key].value)
        ) {
          if (Array.isArray(state.value)) { // flatten array values
            state.value.forEach((value) => url.searchParams.append(key, value))
          } else {
            try {
              url.searchParams.set(key, state.value.toJSON())
            } catch {
              url.searchParams.set(key, state.value)
            }
          }
        }
      })
    globalThis.history.replaceState({}, "", url)
  }

  /**
   * Notify the listener that the state of the component has changed.
   */
  onComponentChanged() {
    throw new Error("onComponentChanged must be implemented")
  }

  onStateChange() {
    this.updateUrlParams()
    this.onComponentChanged()
    this.requestUpdate()
  }

  /**
   * Reset the state of the component to the default state and update the
   * url accordingly.
   */
  resetState() {
    this.state = structuredClone(this.defaultState)
    const url = new URL(globalThis.location)
    Object.keys(this.state).map((key) => url.searchParams.delete(key))
    globalThis.history.replaceState({}, "", url)
    this.onComponentChanged()
    this.requestUpdate()
  }

  /**
   * Checks if the component is in the default state.
   * @returns {boolean} - True if the component is in the default state.
   */
  isDefaultState() {
    return JSON.stringify(this.state) === JSON.stringify(this.defaultState)
  }
}
